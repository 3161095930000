<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" :title="$tc('sms.send-msg.tabs.contact.title', 2)" :subtitle="$tc('import-contacts-component.header', 2)" />
    <div class="page-content container-fluid">
      <div class="row mb-5">
        <div class="import-div col-12 d-flex flex-column align-items-center justify-content-center">
          <img class="phone-img" src="@/assets/import_csv.svg" alt="CSV Image" />
          <p><strong>{{$tc('import-contacts-component.import', 1)}}</strong></p>
          <p>{{$t('import-contacts-component.create')}}</p>
          <div class="btn-wrapper d-flex justify-content-center">
            <button class="btn btn-primary btn-sm d-flex align-items-center" v-modal="{ target: 'create-custom-field-modal' }"><span class="material-symbols-outlined mr-1">add</span>{{$t('import-contacts-component.create-field')}}</button>
            <!-- Toggle import -->
            <b-button variant="primary btn-sm" id="toggle-import-btn" @click="toggleModal"><span class="material-symbols-outlined mr-2">table_view</span>{{$t('generic-str.import')}} .csv</b-button>
            <a href="resources/contacts_import.csv" target="_blank" class="btn-export btn btn-outline-primary btn-sm">{{$t('generic-str.file-example')}}</a>
          </div>
          <!-- Modal -->
          <b-modal id="modal-import" ref="import-modal" size="lg" hide-footer title="Using Component Methods">
            <template #modal-header="{ close }">
              <button class="btn btn-modal-close" @click="close()">
                <span class="material-icons"> close </span>
              </button>
              <!-- Emulate built in modal header close button action -->
              <div class="container">
                <div class="row">
                  <div class="col-12 d-flex align-items-center">
                    <p class="h3 d-inline heading-text">{{$tc('import-contacts-component.header', 2)}}</p>
                  </div>
                  <div class="col">
                    <p v-if="!doImport" class="description-modal">{{$t('import-contacts-component.modal.upload')}}</p>
                    <p v-if="doImport">{{$t('import-contacts-component.modal.confirm')}}</p>
                  </div>
                </div>
              </div>
            </template>
            <form @submit.prevent="importContact">
              <div class="container mb-3">
                <div class="row">
                  <div class="import-config" v-if="doImport">
                    <!-- <div class="row">
                      <div class="col-sm-12">
                        <div
                          class="alert alert-yup alert-dismissible fade show"
                        >
                          {{$tc('import-contacts-component.import', 1)}}:
                          <span class="badge badge-light">{{
                            importObject.rows
                          }}</span>
                        </div>
                        <div
                          class="custom-control custom-checkbox form-check"
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="skip_first"
                            v-model="importObject.skip_first"
                          />
                          <label class="custom-control-label" for="skip_first"
                            >{{$t('contact-list-component.import.line')}}</label
                          >
                        </div>
                      </div>
                    </div> -->
                    <div class="row import-options">
                      <div class="control-group opt-2 mb-3">
                        <label for="skip_first" class="control control-checkbox">
                          <input id="skip_first" type="checkbox" v-model="importObject.skip_first">
                          <div class="control_indicator"></div>
                          <span>{{$t('contact-list-component.import.line')}}</span>
                        </label>
                      </div>
                      <div class="col-sm-12 mb-3"
                      v-for="(column, i) in importObject.columns"
                      :key="column">
                        <p>{{$t('contact-list-component.import.column')}} {{ i }}</p>
                        <select
                          class="form-control"
                          v-model="importObject.columns[i]"
                        >
                          <option
                            v-for="type in columnTypes"
                            v-bind:key="type.name"
                            v-bind:value="type.value"
                            v-text="type.name"
                          ></option>
                          <option
                            v-for="(type, index) in customFields"
                            :key="index"
                            :value="type.id"
                            v-text="type.name"
                          ></option>
                        </select>
                        <!-- Show <hr> in last item -->
                        <hr v-if="i == Object.keys(importObject.columns).length - 1" class="w-100" style="border-top-width: 2px;">
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-sm-12">
                        <p>{{$t('contact-list-component.import.associate')}}</p>
                        <select-input
                          label="name"
                          :reduce="(group) => group.id"
                          v-model="importObject.group_id"
                          :options="groups"
                          :clearable="false"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <p>{{$t('contact-list-component.import.duplicated')}}</p>
                        <select
                          class="form-control"
                          v-model="importObject.on_conflict"
                        >
                          <option value="skip">{{$t('contact-list-component.import.skip')}}</option>
                          <option value="overwrite">{{$t('contact-list-component.import.replace')}}</option>
                        </select>
                      </div>
                    </div>
                    <!-- <div class="card row card-import-col">
                      <div class="row card-import-col-header">
                        <div class="col-4 text-right hide-mobile">
                          <h4>{{$t('contact-list-component.import.column')}}</h4>
                        </div>
                        <div class="col-6 hide-mobile">
                          <h4>{{$t('generic-str.type')}}</h4>
                        </div>
                      </div>
                      <div class="card-import-body">
                        <div
                          class="row"
                          v-for="(column, i) in importObject.columns"
                          :key="column"
                        >
                          <div
                            class="col-sm-4 colum-margin text-right text-left-m"
                          >
                            <span>{{$t('contact-list-component.import.column')}} {{ i }}</span>
                          </div>
                          <div class="col-sm-6 colum-margin">
                            <select
                              class="form-control"
                              v-model="importObject.columns[i]"
                            >
                              <option
                                v-for="type in columnTypes"
                                v-bind:key="type.name"
                                v-bind:value="type.value"
                                v-text="type.name"
                              ></option>
                              <option
                                v-for="(type, index) in customFields"
                                :key="index"
                                :value="type.id"
                                v-text="type.name"
                              ></option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <div class="import-wrapper" v-if="!doImport">
                    <div class="container-circular-progress-bar" v-if="fileLoaded">
                        <div class="circular-progress">
                            <span class="progress-value">0%</span>
                        </div>
                        <!-- <span class="text-progress">HTML &amp; CSS</span> -->
                    </div>
                    <img class="csv-img" src="@/assets/import_csv.svg" alt="CSV Image" v-if="!fileLoaded" />
                    <div class="w-100"></div>
                    <p>{{$t('import-contacts-component.modal.drag')}}</p>
                    <div class="w-100"></div>
                    <input
                      id="validatedCustomIconLabel"
                      type="file"
                      accept=".csv"
                      @change="processFile($event)"
                      class="custom-file-input" required role="button" />
                    <label
                      class="custom-file-label-modal"
                      for="validatedCustomIconLabel">
                      {{ file_name }}
                    </label>
                  </div>
                  <!-- <progress id="progressBar" max="100" :value.prop="uploadPercentage"></progress> -->
                  <small v-if="!doImport">{{$t('import-contacts-component.modal.info')}}</small>
                  <div class="progress-wrapper d-flex align-items-center w-100">
                    <label class="label-file custom-file-label-modal" for="validatedCustomIconLabel">{{ file_name }}</label>
                    <img src="@/assets/csv-file.svg" alt="">
                    <b-progress id="progressBar" :value="uploadPercentage" :max="100" variant="yup"></b-progress>
                    <span id="percentage"></span>
                  </div>
                </div>
              </div>
              <div class="container">
                <hr class="w-100" style="border-top-width: 2px;">
                <div class="row">
                  <div class="col-lg-12 d-flex">
                    <div class="button-wrapper">
                      <!-- <button class="btn btn-outline-primary" @click="close">{{$t('app.cancel')}}</button> -->
                      <button
                        v-if="!doImport"
                        :class="{
                          'qt-loader qt-loader-mini qt-loader-right': isSending,
                        }"
                        :disabled="isSending || fileLoaded === false"
                        type="submit"
                        class="btn btn-primary"
                      >
                        {{$t('generic-str.import')}}
                      </button>
                      <a
                        type="button"
                        class="btn btn-outline-primary"
                        v-if="doImport"
                        @click="cancelImport"
                        >{{$t('app.cancel')}}</a
                      >
                      <a
                        v-if="doImport"
                        :class="{
                          'qt-loader qt-loader-mini qt-loader-right': isSending,
                        }"
                        :disabled="isSending"
                        type="button"
                        @click="importContactFinish"
                        class="btn btn-primary"
                        >{{$t('generic-str.status.lbl-finish')}}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <!-- <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Close Me</b-button>
            <b-button class="mt-2" variant="outline-warning" block @click="toggleModal">Toggle Me</b-button> -->
          </b-modal>
        </div>
      </div>
      <!-- <div class="card">
        <div class="card-body block-el">
          <div class="row">
            <div class="col-10"></div>
            <div class="col-2">
              <button class="btn btn-secondary" v-modal="{ target: 'create-custom-field-modal' }">
                Criar campo
              </button>
            </div>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body block-el p-0">
              <!-- <form @submit.prevent="importContact" class="form-horizontal">
                <div class="modal-body">
                  <div class="form-body">
                    <div class="import-config" v-if="doImport">
                      <div class="row">
                        <div class="col-sm-12">
                          <div
                            class="alert alert-info alert-dismissible fade show"
                          >
                            {{$tc('import-contacts-component.import', 1)}}:
                            <span class="badge badge-light">{{
                              importObject.rows
                            }}</span>
                          </div>
                          <div
                            class="custom-control custom-checkbox form-check"
                          >
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="skip_first"
                              v-model="importObject.skip_first"
                            />
                            <label class="custom-control-label" for="skip_first"
                              >{{$t('contact-list-component.import.line')}}</label
                            >
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <hr />
                        </div>
                      </div>
                      <div class="card row card-import-col">
                        <div class="row card-import-col-header">
                          <div class="col-4 text-right hide-mobile">
                            <h4>{{$t('contact-list-component.import.column')}}</h4>
                          </div>
                          <div class="col-6 hide-mobile">
                            <h4>{{$t('generic-str.type')}}</h4>
                          </div>
                        </div>
                        <div class="card-import-body">
                          <div
                            class="row"
                            v-for="(column, i) in importObject.columns"
                            :key="column"
                          >
                            <div
                              class="col-sm-4 colum-margin text-right text-left-m"
                            >
                              <span>{{$t('contact-list-component.import.column')}} {{ i }}</span>
                            </div>
                            <div class="col-sm-6 colum-margin">
                              <select
                                class="form-control"
                                v-model="importObject.columns[i]"
                              >
                                <option
                                  v-for="type in columnTypes"
                                  v-bind:key="type.name"
                                  v-bind:value="type.value"
                                  v-text="type.name"
                                ></option>
                                <option
                                  v-for="(type, index) in customFields"
                                  :key="index"
                                  :value="type.id"
                                  v-text="type.name"
                                ></option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div class="col-sm-12">
                        <hr />
                      </div>
                      <div class="row">
                        <span class="col-md-4 text-right text-left-m">
                          <h5>{{$t('contact-list-component.import.associate')}}</h5>
                        </span>
                        <div class="col-md-6">
                          <select-input
                            label="name"
                            :reduce="(group) => group.id"
                            v-model="importObject.group_id"
                            :options="groups"
                            :clearable="false"
                          />
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <span class="col-md-4 text-right text-left-m">
                          <h5>{{$t('contact-list-component.import.duplicated')}}</h5>
                        </span>
                        <div class="col-md-6">
                          <select
                            class="form-control"
                            v-model="importObject.on_conflict"
                          >
                            <option value="skip">{{$t('contact-list-component.import.skip')}}</option>
                            <option value="overwrite">{{$t('contact-list-component.import.replace')}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row" v-if="!doImport">
                      <label
                        class="control-label text-right col-md-4 text-left-m"
                        >{{$t('generic-str.import')}}:</label
                      >
                      <div class="col-md-6">
                        <div
                          class="custom-control custom-radio custom-control-inline"
                        >
                          <input
                            type="radio"
                            v-model="type"
                            value="file"
                            checked
                            id="typeFile"
                            name="file_type"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="typeFile"
                            >{{$t('generic-str.archive')}}</label
                          >
                        </div>
                        <div
                          class="custom-control custom-radio custom-control-inline"
                        >
                          <input
                            type="radio"
                            v-model="type"
                            value="text"
                            id="typeText"
                            name="file_type"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="typeText"
                            >{{$tc('options-div.types.text', 1)}}</label
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="form-group row"
                      v-if="type == 'file' && !doImport"
                    >
                      <label
                        class="control-label text-right col-md-4 text-left-m"
                        >{{$tc('sms.send-msg.tabs.contact.title', 2)}}:</label
                      >
                      <div class="col-md-6">
                        <div class="custom-file">
                          <div class="row no-margin">
                            <div class="col-lg-7 col-md-7">
                              <input
                                type="file"
                                accept=".csv"
                                @change="processFile($event)"
                                class="custom-file-input"
                                required
                                id="validatedCustomIconLabel"
                                role="button"
                              />
                              <label
                                class="custom-file-label btn-icon"
                                for="validatedCustomIconLabel"
                                >{{ file_name }}</label
                              >
                            </div>
                          </div>
                          <label class="file-tip"
                            >{{$t('generic-str.file-csv')}}</label
                          >
                          <div class="invalid-feedback"></div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="form-group row"
                      v-if="type == 'text' && !doImport"
                    >
                      <label
                        class="control-label text-right col-md-4 text-left-m"
                        >{{$tc('sms.send-msg.tabs.contact.title', 2)}}:</label
                      >
                      <div class="col-md-6">
                        <div class="custom-file">
                          <textarea
                            class="form-control"
                            v-model="form.content"
                            rows="4"
                            required
                          ></textarea>
                          <label class="file-tip"
                            >{{$t('contact-list-component.import.csv')}}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    v-if="!doImport"
                    :class="{
                      'qt-loader qt-loader-mini qt-loader-right': isSending,
                    }"
                    :disabled="isSending"
                    type="submit"
                    class="btn btn-primary"
                  >
                    {{$t('generic-str.import')}}
                  </button>
                  <a
                    type="button"
                    class="btn btn-secondary"
                    v-if="doImport"
                    @click="cancelImport"
                    >{{$t('generic-str.return')}}</a
                  >
                  <a
                    v-if="doImport"
                    :class="{
                      'qt-loader qt-loader-mini qt-loader-right': isSending,
                    }"
                    :disabled="isSending"
                    type="button"
                    @click="importContactFinish"
                    class="btn btn-success"
                    >{{$t('generic-str.status.lbl-finish')}}</a
                  >
                </div>
              </form> -->
            </div>
          </div>

          <div class="card" v-if="files.length > 0 && !doImport">
            <div class="card-body block-el p-0">
              <div class="modal-body">
                <div class="form-body">
                  <!-- <div class="row card-import">
                    <h3 class="text-secondary">{{$t('generic-str.imported')}}</h3>
                  </div> -->
                  <div class="row table-responsive card-import card-custom">
                    <table class="table table-borderless table-import">
                      <thead class="">
                        <tr>
                          <th>{{$tc('generic-str.name', 1)}}</th>
                          <th>{{$t('generic-str.date')}}</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody v-for="imported in files" :key="imported.id">
                        <tr class="tr-height">
                          <td>{{ imported.name }}</td>
                          <td>{{ imported.created_at | formatDate }}</td>
                          <td>
                            <span
                              class="badge badge-optional"
                              v-if="imported.status == 'queued'"
                              >&#x2022; {{$t('generic-str.status.lbl-queue')}}</span
                            >
                            <span
                              class="badge badge-scheduled"
                              v-if="imported.status == 'new'"
                              >&#x2022; {{$t('generic-str.status.lbl-new')}}</span
                            >
                            <span
                              class="badge badge-success"
                              v-if="imported.status == 'done'"
                              >&#x2022; {{$tc('generic-str.status.lbl-finished', 2)}}</span
                            >
                            <span
                              class="badge badge-danger"
                              v-if="imported.status == 'failed'"
                              >&#x2022; {{$t('generic-str.status.lbl-failure')}}</span
                            >
                          </td>
                          <td align="right">
                            <button
                              type="button"
                              @click="configImport(imported)"
                              v-if="imported.status == 'new'"
                              class="btn btn-outline-primary btn-sm"
                            >
                              {{$t('generic-str.status.lbl-finish')}}
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-if="!loaded" class="qt-block-ui" />
                    <br />
                    <pagination :lastPage="pages" @change="fetch" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <create-product-modal id="create-product-modal" @sent="fetch" /> -->
    <create-custom-field-modal id="create-custom-field-modal" @created="fetchCustomFields()" />
  </div>
</template>

<script>
// @ is an alias to /src
import axios from '@/plugins/axios';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import SelectInput from '@/components/SelectInput.vue';
import ContactService from '@/services/contact.service';
import Pagination from '@/components/rebranding/Pagination.vue';
import CreateCustomFieldModal from '@/components/contacts/CreateCustomFieldModal.vue';

export default {
  name: 'ImportContact',
  components: {
    PageHeader,
    SelectInput,
    Pagination,
    CreateCustomFieldModal,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/contacts',
        2: '/contacts/import',
      },
      columnTypes: [
        { value: 'first_name', name: 'Primeiro nome' },
        { value: 'last_name', name: this.$t('generic-str.lbl-last-name') },
        { value: 'mobile_number', name: this.$t('generic-str.lbl-cellphone') },
        { value: 'email', name: 'Email' },
        { value: '', name: this.$t('contact-list-component.import.no-import') },
      ],
      groups: [],
      customFields: [],
      type: 'file',
      fileImport: {},
      uploadPercentage: 0,
      fileLoaded: false,
      loaded: true,
      files: [],
      file_name: this.$t('import-contacts-component.modal.select'),
      doImport: false,
      importObject: {
        on_conflict: 'skip',
        skipe_first: false,
        group_id: null,
        columns: [],
      },
      newImport: {},
      form: {
        content: '',
        group: '',
        page: '',
      },
      pages: 1,
      isSending: false,
    };
  },
  created() {
    this.fetchCustomFields();
    this.fetchGroups();
    this.fetch(1);
  },
  methods: {
    showModal() {
      this.$refs['import-modal'].show();
    },
    hideModal() {
      this.$refs['import-modal'].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['import-modal'].toggle('#toggle-import-btn');
    },
    getImporteds() {
      console.log('nothing...');
    },
    cancelImport() {
      this.fileLoaded = false;
      this.doImport = false;
      this.isSending = false;
    },
    configImport(importObject) {
      this.doImport = true;
      this.isSending = false;
      this.importObject = importObject;
    },
    fetch(page) {
      this.form.page = page;
      this.loaded = false;
      ContactService.getImports(this.form).then(
        (response) => {
          this.loaded = true;
          this.files = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetchCustomFields() {
      axios().get('/contacts/custom-field').then((response) => {
        this.customFields = [];

        const customFields = this.customFields;

        response.data?.forEach((field) => {
          if (field.id && field.name) {
            customFields.push({
              name: field.name,
              id: field.id,
            });
          }
        });

        console.log('CUSTOM FIELD', customFields, response);
      });
    },
    fetchGroups() {
      ContactService.getGroups().then(
        (response) => {
          this.groups = response;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    importContactFinish() {
      // console.log('importContactFinish');
      this.newImport = {
        skip_first: this.importObject.skip_first,
        group_id: this.importObject.group_id,
        columns: this.importObject.columns,
        on_conflict: this.importObject.on_conflict,
      };
      this.isSending = true;
      ContactService.importContactFinish(
        this.importObject.id,
        this.newImport,
      ).then(
        () => {
          // this.$router.go(this.$router.currentRoute);
          this.cancelImport();
          this.fetch(1);
          this.hideModal();
        },
        (error) => {
          this.content = error;
        },
      );
    },
    processFile(event) {
      event.preventDefault();
      this.fileImport = event.target.files[0];
      console.info('Selected file: ', this.fileImport);
      this.fileLoaded = true;
      this.file_name = `${this.$t('import-contacts-component.modal.selected')}: ${this.fileImport.name}`;
    },
    importContact() {
      this.isSending = true;
      if (this.type === 'file') {
        const formData = new FormData();
        formData.append('content', this.fileImport);
        ContactService.importContactFile(formData).then(
          (response) => {
            this.configImport(response);
            this.getImporteds();
            // this.$root.$emit('updated.contacts');
            this.isSending = false;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        );
      } else {
        ContactService.importContact(this.form).then(
          (response) => {
            this.configImport(response);
            this.getImporteds();
            // this.$root.$emit('updated.contacts');
            this.isSending = false;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn i {
  line-height: 1;
  color: inherit;
}
.btn {
  margin-bottom: 5px;
}
.card-import {
  margin: 0px;
}
.card-import-col {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}
.card-import-col-header {
  border-bottom: solid 1px #efefef;
  height: 45px;
  margin-bottom: 10px;
  background: #f3f3f3;
  margin-top: 0px;
  padding-top: 0px;
  padding: 10px;
}
.card-import-body {
  padding: 15px;
}
.colum-margin {
  margin-bottom: 5px;
}
.file-tip {
  color: #ccc;
  margin-top: 5px;
}
.tr-height {
  height: 50px;
  border-bottom: solid 1px #f3f3f3;
}
.top-10 {
  margin-top: 10px !important;
}
.btn-export {
  padding: 8px 20px !important;
}

/* Rebranding */
#toggle-import-btn {
  display: flex;
  align-items: center;
}
.btn-wrapper {
  gap: 2rem;
  @media (max-width: 767.98px) {
    flex-wrap: wrap;
    gap: 1rem !important;
  }
}
.import-div {
  p:first-of-type {
    color: var(--foreground);
  }
  p:last-of-type {
    color: var(--gray-font-color-4);
  }
}
.card-import tr th {
  font-weight: bold;
}
.card-import {
  tbody {
    tr {
      border-bottom-color: transparent;
    }
  }
}
::v-deep #modal-import {
  .modal-lg {
    max-width: 645px !important;
  }
  .custom-file-input {
    position: absolute;
    height: 100%;
    top: 0;
    z-index: 1;
    cursor: default;
  }
  .heading-text {
    font-weight: bold;
  }
  .description-modal {
    color: var(--gray-font-color-2);
  }
  .btn-modal-close {
    top: 10px;
    position: absolute;
    right: 0;
    z-index: 5;
    span {
      transition: .5s;
    }
    &:hover span, &:focus span{
      color: var(--gray-dark ) !important;
    }
  }
  .modal-header {
    button {
      .material-icons {
        color: var(--gray-font-color);
      }
    }
  }
  .modal-content {
    padding: 0 !important;
  }
  small {
    margin: 7px 10%;
    color: var(--gray-font-color-2);
  }
  .import-wrapper {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    border: 2px dashed var(--clr-yup-purple);
    border-radius: 12px;
    margin: 0 10%;
    padding: 1rem 0;

    @media (max-width: 767.98px) {
      margin: 0;
    }

    p {
      width: 100%;
      text-align: center;
      margin-bottom: 1rem;
      position: relative;
      z-index: 2;
    }

    img.csv-img, .custom-file-label-modal {
      margin: 0 auto;
      margin-bottom: 1rem;
    }
    .custom-file-label-modal {
      background-color: #FFF;
      color: var(--clr-yup-purple);
      padding: .75rem;
      border: 1px solid var(--clr-yup-purple);
      border-radius: 4px;
      cursor: pointer;
      font-weight: 400;
      transition: .5s;
      position: relative;
      z-index: 2;

      &:hover, &:focus {
        border-color: var(--clr-yup-purple-hover) !important;
        background-color: var(--clr-yup-purple-hover) !important;
        color: #fff !important;
      }
    }
  }
  .button-wrapper {
    display: flex;
    gap: 2rem;
    margin-left: auto;
  }
  .import-config {
    width: 100%;
    margin: 0 10%;

    p {
      font-size: 1rem;
      margin-bottom: .3rem;
    }
  }
  .alert-yup {
    background-color: var(--clr-yup-purple);
  }
  .import-options .control-checkbox {
    display: flex;
    gap: .5rem;
    margin-left: 1rem;
  }
  .progress-wrapper {
    position: relative;
    border: 1px solid #E0E3E6;
    border-radius: 8px;
    margin: 0 10%;
    margin-top: 1rem;
    padding: 1rem;

    #percentage {
      margin-top: 1rem;
      margin-left: .725rem;
      font-weight: 400;
    }

    .label-file {
      position: absolute;
      left: 70px;
      top: 10px;
    }

    img {
      margin-right: 1rem;
    }
  }
  #progressBar {
    width: 100%;
    margin-top: 1rem;

    /* &:before {
      content: '';
      background-image: url(/assets/import_csv.svg);
      width: 31px;
      height: 36px;
    } */
  }
  .control-group.opt-2.mb-3 {
    background: none !important;
  }
  .container-circular-progress-bar{
    display: flex;
    width: 100%;
    padding: 50px 0;
    border-radius: 8px;
    background: #fff;
    row-gap: 30px;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}
  .circular-progress{
      position: relative;
      height: 200px;
      width: 200px;
      border-radius: 50%;
      background: conic-gradient(var(--clr-yup-purple) 3.6deg, #ededed 0deg);
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .circular-progress::before{
      content: "";
      position: absolute;
      height: 170px;
      width: 170px;
      border-radius: 50%;
      background-color: #fff;
  }
  .progress-value{
      position: relative;
      font-size: 40px;
      font-weight: 600;
      color: var(--clr-yup-purple);
  }
  .text-progress{
      font-size: 30px;
      font-weight: 500;
      color: #606060;
  }
}
</style>
<style>
body {
  background-color: var(--background-3);
}
</style>
